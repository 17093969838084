import React from "react";
import { AxiosResponse } from "axios";
import DataGrid, {
    Pager,
    Paging,
    FilterRow,
    Column,
    Sorting,
    Editing,
    Export,
    SearchPanel,
} from "devextreme-react/data-grid";
import { LoadIndicator, LoadPanel } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import MatchingBulkInviteGrid from "./MatchingBulkInviteGrid";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import FileService from "../../../services/FileService";
import MatrixGridService, { BulkInviteRowItem, BULK_FLOW, BulkInviteMatchingRowItem } from '../../../services/MatrixService';
import notify from 'devextreme/ui/notify';

export const APIFLOW = {
    invites: 'invites',
    users: 'users',
    create:'create'
}
//props
interface BulkInvitesContainerProps {
    navigateFrom: string;
}

// State
interface BulkInvitesContainerState {
    pastedGridDataSource: BulkInviteRowItem[];
    matchingGridDataSource: any[];
    findMatchesClicked: boolean;
    showFindMatches: boolean;
    showSendInvites: boolean;
    showMatchingGrid: boolean;
    showLoadPanel: boolean;
    selectedMatchingData: any[];
    reloadMatchingGrid: boolean;
    userResponseData: any[];
}

class BulkInvitesContainer extends React.Component<BulkInvitesContainerProps> {
    //Initialize the component's state.
    state: BulkInvitesContainerState;
    dataGridRef: any;
    fileService: FileService;
    matrixGridService: MatrixGridService;
    constructor(props: BulkInvitesContainerProps) {
        super(props);
        // Initialize state and services/utils
        this.dataGridRef = null;
        this.fileService = new FileService();
        this.matrixGridService = new MatrixGridService();
        this.state = {
            pastedGridDataSource: [],
            showLoadPanel: false,
            showFindMatches: false,
            showMatchingGrid: false,
            showSendInvites: false,
            matchingGridDataSource: [],
            findMatchesClicked: false,
            selectedMatchingData: [],
            reloadMatchingGrid: false,
            userResponseData: [],
        };
    }

    componentDidMount() {
        let obj = this.getDefaultObject(0);
        let stateArr = this.state.pastedGridDataSource;
        stateArr.push(obj);
        this.setState({ pastedGridDataSource: stateArr })
    }

    //When a component is updated this lifecycle method is called, and a change in props here would trigger this.
    componentDidUpdate(prevProps: BulkInvitesContainerProps, prevState: BulkInvitesContainerState) {
    }


    isSendInvitesDisabled = () => {
        let disabled = true;
        let inviteUsers = this.state.selectedMatchingData.filter(a => a.canInviteUser);
        if (this.state.matchingGridDataSource?.length > 0 && inviteUsers?.length > 0) {
            disabled = false;
        }
        return disabled;
    }

    isMissingUsersDisabled = () => {
        let disabled = true;
        let missingUser = this.state.selectedMatchingData.filter(a => a.canCRUDUser);
        if (missingUser && missingUser?.length > 0) {
            disabled = false;
        }
        return disabled;
    }

    isCreateUserDisabled = () => {
        let disabled = true;
        //let inviteUsers = this.state.selectedMatchingData.filter(a => a.emailMatched);
        if (this.state.matchingGridDataSource?.length > 0 && this.state.selectedMatchingData?.length > 0) {
            disabled = false;
        }
        return disabled;
    }

    onClickFindMatches = () => {
        // let uniquePastedRow = this.removeDuplicate(this.state.pastedGridDataSource);
        // this.setState({ pastedGridDataSource: uniquePastedRow, findMatchesClicked: true, showMatchingGrid: true, reloadMatchingGrid: false });
        this.setState({ findMatchesClicked: true, showMatchingGrid: true, reloadMatchingGrid: false });
    };

    onClickCheckForDuplicates = () => {
        // let uniquePastedRow = this.removeDuplicate(this.state.pastedGridDataSource);
        // this.setState({ pastedGridDataSource: uniquePastedRow,findMatchesClicked: true, showMatchingGrid: true });
        this.setState({ findMatchesClicked: true, showMatchingGrid: true });
    }

    displayBooleanContent = (Val: any) => {
        let valToShow: string = "";
        if (Val !== null) {
            if (Val) {
                valToShow = "Yes";
            }
            else {
                valToShow = "No";
            }
        }
        return (
            <div style={{ margin: '10px', minHeight: '10px' }}>
                {
                    valToShow == 'Yes' ? <b><span style={{ color: '#32c5ff'}}>{valToShow}</span></b> : <span style={{ color: 'red'}}>{valToShow}</span>
                }
                {/* <span>{valToShow}</span> */}
            </div>
        );
    }

    calculateBooleanContent = (Val: any) => {
        let valToShow: string = '';
        if (Val) {
            valToShow = 'Yes';
        }
        else {
            valToShow = 'No';
        }
        return valToShow;
    }

    displayErrorMessage = (cellInfo:any)=> {
        let errMsgArr = cellInfo?.data?.data?.errorMessage || [];
        return (
            <span>
                <ul className="mb-0">
                    {errMsgArr && errMsgArr.length > 0
                        ? errMsgArr.map((item: any, uniqueKey: number) => {
                              return (
                                  <li key={uniqueKey}>
                                      <b className={item.ErrorType == "Warning" ? `text-warning` : `text-danger`}>
                                          {item.columnName} : {item.errorMessage}
                                      </b>
                                      {/* : "{item.errorType} - {item.errorMessage}" */}
                                  </li>
                              );
                          })
                        : null}
                </ul>
            </span>
        );
    }

    getDefaultObject = (index: any) => {
        let obj: BulkInviteRowItem = {
            name: 'click here to paste from excel',
            email: '',
            providerId: '',
            provider: '',
            userRole: '',
            itemFound: index == 0 ? null : false,
            isUserRecordOk: index == 0 ? null : false,
            isUserRoleOk : index ==0 ?null :false,
            isContactRecordOk :index == 0 ? null : false,
            itemInvited: index == 0 ? null : false,
            uuid: index,
            recordId : `${index}`,
            errorMessage : [],
        }
        return obj;
    }

    checkForRowsToAdd = (currentRowIndex: any, pastedRows: any) => {
        let patedsRowLength = pastedRows.length;
        let gridRows = this.state.pastedGridDataSource;
        let availableRows = gridRows.slice(currentRowIndex, gridRows.length)
        if (availableRows?.length <= patedsRowLength) {
            let rowsToadd = patedsRowLength - availableRows.length;
            // for (let i = 0; i <= rowsToadd; i++) {
            for (let i = 0; i < rowsToadd; i++) {
                let index = gridRows.length;
                let obj = this.getDefaultObject(index);
                gridRows.push(obj);

            }
        }
        return gridRows;
    }

    removeDuplicate = (stateObj: any) => {
        stateObj = stateObj.filter((value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => (
                t.email === value.email
            ))
        ).map((a: any, index: any) => { return { ...a, uuid: index,recordId:`${index}` } })
        return stateObj;
    }

    handlePaste = (editEvent: any, e: any, currentRow: any, currentDataField: any) => {
        let pastedData = e.clipboardData.getData('Text');
        let pastedRows = pastedData.split('\n')?.filter((a: any) => a !== '');
        let rowIndex = currentRow.dataIndex;
        //rowIndex = currentRow.data.uuid;
        let stateObj = this.checkForRowsToAdd(rowIndex, pastedRows);
        let allRows = stateObj;

        pastedRows.forEach((element: any, rindex: any) => {
            let roToUpdate = rowIndex + rindex;
            let currPastedRow = pastedRows[rindex];
            // let splittedCells = pastedRows[rindex].split('\t');
            let splittedCells = currPastedRow.replace(/(\r)/gm, '').split('\t');
            let currRow = allRows[roToUpdate]
            const dataFields = Object.keys(currRow).slice(0, 5);
            //check for duplicate
            for (let fieldID = 0; fieldID < dataFields.length; fieldID++) {
                if (dataFields[fieldID] === currentDataField) {
                    for (let i = 0; i < dataFields.length - fieldID && i < splittedCells.length; i++) {
                        let key = dataFields[fieldID + i];
                        let val = splittedCells[i];
                        this.dataGridRef.instance.cellValue(
                            roToUpdate,
                            key,
                            val
                        );
                        if (key == 'name') {
                            currRow.name = val;
                        } else if (key == 'email') {
                            currRow.email = val;
                        } else if (key == 'providerId') {
                            currRow.providerId = val;
                        } else if (key == 'provider') {
                            currRow.provider = val;
                        } else if (key == 'userRole') {
                            currRow.userRole = val;
                        }
                        if (currRow.name == 'click here to paste from excel') {
                            currRow.name = '';
                        }
                        currRow.itemFound = null;
                        currRow.itemInvited = null;
                        currRow.isUserRecordOk = null;
                        currRow.isUserRoleOk = null;
                        currRow.isContactRecordOk = null;
                        currRow.errorMessage = [];
                        //}
                    }

                }
            }

        });
        this.dataGridRef.instance.saveEditData();

        let show = false;
        if (stateObj && stateObj.some(a => a.email?.trim())) {
            show = true;
        }
        //remove duplicate
        //stateObj = this.removeDuplicate(stateObj);
        this.setState({ pastedGridDataSource: stateObj, showFindMatches: show });
        editEvent.cancel = true;
    }

    onCellClick = (clickEvent: any) => {
        const currentDataField = clickEvent.column.dataField;
        const currentRow = clickEvent.row;

        clickEvent.cellElement.onpaste = (pasteEvent: any) => {
            this.handlePaste(clickEvent, pasteEvent, currentRow, currentDataField);
        }

    }

    onRefreshClick = () => {
        let obj = this.getDefaultObject(0);
        let stateArr = [];
        stateArr.push(obj);

        this.setState({
            pastedGridDataSource: stateArr,
            showFindMatches: false,
            findMatchesClicked: false,
            showSendInvites: false,
            matchingGridDataSource: [],
            showMatchingGrid: false,
            selectedMatchingData: [],
            reloadMatchingGrid: false,
            userResponseData: []
        })
    }

    updateFoundRecords = (matchedGridSource: any[], pastedGridSource: any[]) => {
        this.setState({
            matchingGridDataSource: matchedGridSource,
            pastedGridDataSource: pastedGridSource,
            showSendInvites: true,
            findMatchesClicked: false,
            selectedMatchingData: [],
            reloadMatchingGrid: false
        })
    }

    setSelectedRows = (rows: any) => {
        this.setState({ selectedMatchingData: rows });
    }

    prepareRequestObj_sendInvites = () => {
        let inviteUser = this.state.selectedMatchingData.filter(a => a.canInviteUser) || [];
        let request = inviteUser.map((item: BulkInviteMatchingRowItem) => {
            return {
                id: item.contactId,
                displayName: item.name,
                email: item.email
            }
        })
        return request;
    }

    prepareRequestObj_missingUsers = () => {
        let users = this.state.selectedMatchingData.filter(a => a.canCRUDUser) || [];
        let request = users.map((item: BulkInviteMatchingRowItem) => {
            return {
                contactId: item.contactId,
                contactName: item.name,
                contactEmail: item.email,
                providerId: item.providerId_fromAPI,
                provider: item.provider_fromAPI,
                contactTypeId: item.contactTypeId,
                contactType: item.contactType_fromAPI,
                contactActive: item.contactActive,
                userId: item.userId,
                userActive: item.userActive,
                userRole: item.userRole_fromAPI,
                userRoleId: item.userRoleId,
                newUserRole: item.userRole
            }
        })
        return request;
    }

    prepareRequestObj_createUsers = () => {
        let users = this.state.selectedMatchingData || [];
        let request = users.map((item: BulkInviteMatchingRowItem) => {
            return {
                recordId:item.recordId,
                contactId: '',
                name: item.name,
                email: item.email,
                businessEntityId: item.providerId,
                role: item.contactType,
                
                
                // "contactId": "",
                // "name": "Test User",
                // "email": "test2@test.com",
                // "businessEntityId": "200",
                // "role": "Provider Scheduler"
            }
        })
        return request;
    }

    onClickSendInvites = () => {
        let payload: any = [];
        payload = this.prepareRequestObj_sendInvites();
        this.setState({ showLoadPanel: true });
        this.matrixGridService.sendBulkInvites(payload)
            .then((res: any) => this.handleSuccess(res, APIFLOW.invites, payload))
            .catch(this.handleFailure);
    }

    handleSuccess = (response: AxiosResponse<any>, flow: string, requestArr: any) => {
        if (flow) {
            if (flow == APIFLOW.invites) {
                let pastedData = this.state.pastedGridDataSource;
                let data = response?.data?.data || [];
                let updated = pastedData.map((item: any) => {
                    let filter = data.filter((resItem: any) => {
                        return item.contactId == resItem.id
                    });
                    if (filter && filter.length && filter.length > 0) {
                        let filterd = filter[0];
                        let isSuccess = filterd.success ? true :false;
                        if(isSuccess)
                        {
                            return { ...item, itemInvited: true,errorMessage:[] }
                        } else {
                            return { ...item, itemInvited: false,errorMessage:filterd.validationErrors || [] }
                        }
                    }
                    else {
                        return { ...item, itemInvited: false,errorMessage: [] }
                    }
                })
                this.setState({ pastedGridDataSource: updated, showLoadPanel: false })
            } else if (flow == APIFLOW.users) {
                let data = response?.data?.data || [];
                this.setState({ userResponseData: data, showLoadPanel: false, reloadMatchingGrid: true })
            } else if(flow == APIFLOW.create){
                // let pastedData = this.state.pastedGridDataSource;
                // let data = response?.data?.data || [];
                // let updated = pastedData.map((item: any) => {
                //     let filter = data.filter((resItem: any) => {
                //         return item.recordId == resItem.recordId
                //     });
                //     if (filter && filter.length && filter.length > 0) {
                //         if(filter[0].contactId){
                //             return { ...item, itemInvited: true }
                //         }
                //         return { ...item, itemInvited: false }
                //     }
                //     else {
                //         return { ...item, itemInvited: false }
                //     }
                // })
                // this.setState({ pastedGridDataSource: updated, showLoadPanel: false })
                let data = response?.data?.data || [];
                this.setState({ userResponseData: data, showLoadPanel: false, reloadMatchingGrid: true })
            }
            notify({ message: "Operations Completed!", width: 300, shading: true, position: "center" }, 'success', 600);
        }
    };

    handleFailure = (error: any) => {
        this.setState({
            showLoadPanel: false
        },()=>{
            notify({ message: "Sorry! The operation could not be completed.", width: 300, shading: true }, 'error', 600);
        });
        var respMessage: string = "Bulk operations failed with response: " + JSON.stringify(error);

        if (!this.matrixGridService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    onClickCreateUpdateMissingUsers = () => {
        let payload: any = [];
        payload = this.prepareRequestObj_missingUsers();
        this.setState({ showLoadPanel: true });
        this.matrixGridService.createOrUpdateUsers(payload)
            .then((res: any) => this.handleSuccess(res, APIFLOW.users, payload))
            .catch(this.handleFailure);
    }

    onClickCreateBulkContacts = ()=>{
        let payload: any = [];
        payload = this.prepareRequestObj_createUsers();
        this.setState({ showLoadPanel: true });
        this.matrixGridService.createContacts(payload)
            .then((res: any) => this.handleSuccess(res, APIFLOW.create, payload))
            .catch(this.handleFailure);
    }

    renderMatchingGrid = () => {
        let header = this.isInviteFlow() ? 'Matching Contacts' : 'Contacts List';
        if (this.state.showMatchingGrid) {
            return <>
                <h2 className="page-title__black-table">{header}</h2>
                <MatchingBulkInviteGrid
                    updateFoundRecords={this.updateFoundRecords}
                    setSelectedRows={this.setSelectedRows}
                    findMatchesClicked={this.state.findMatchesClicked}
                    pastedDetails={this.state.pastedGridDataSource}
                    navigateFrom={this.props.navigateFrom}
                    reloadMatchingGrid={this.state.reloadMatchingGrid}
                    userResponseData={this.state.userResponseData}>
                </MatchingBulkInviteGrid>
            </>
        }
    }

    onDownloadClick = () => {
        let downloadId = this.isInviteFlow() ? 'bulkinvite' : (this.isCreateFlow() ? 'bulkcreatecontacts':'');
        if (downloadId) {
            this.setState({
                showLoadPanel: true
            });
            this.fileService
                .downloadTemplate(downloadId)
                .then(this.handleDownloadSuccess)
                .catch(this.handleDownloadError);
        }
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        this.setState({
            showLoadPanel: false
        });
        if(this.isInviteFlow()){
            saveAs(response.data, 'template_bulk_invite.xlsx');
        } else if(this.isCreateFlow()){
            saveAs(response.data, 'template_create_bulk_contacts.xlsx');
        }
    };

    handleDownloadError = (error: any) => {
        this.setState({
            showLoadPanel: false
        });
        var respMessage: string = "Download template failed with response: " + JSON.stringify(error);

        if (!this.fileService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    isInviteFlow = () => {
        let isFlow = false;
        if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_INVITES) {
            isFlow = true;
        }
        return isFlow;
    }

    isCreateFlow = () => {
        let isFlow = false;
        if (this.props.navigateFrom && this.props.navigateFrom == BULK_FLOW.BULK_CREATE_CONTACTS) {
            isFlow = true;
        }
        return isFlow;
    }

    render() {
        let header = this.isInviteFlow() ? 'Bulk Invite' : (this.isCreateFlow() ? 'Create Bulk Contacts' : '');
        let isContactFlow = this.isCreateFlow();
        let isInviteFlow = this.isInviteFlow();
        return (
            <>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.showLoadPanel} />
                <>
                    <div style={{ display: 'flex' }}>
                        <h2 className="page-title__black-table">
                            {header}
                            <button
                                className="status-flow__refresh-button btn icon-btn"
                                aria-label="Refresh the current manage pay dates"
                                onClick={this.onRefreshClick}
                            >
                                <FontAwesomeIcon icon={faSync} title='reset grid data' />
                            </button>
                        </h2>
                        <h5 style={{ padding: '15px 0px 0px 15px' }}>
                            <Link
                                onClick={(e) => {
                                    this.onDownloadClick();
                                }}
                                className="approval-query-column"
                                to="#"
                            >
                                Download Template
                            </Link>
                        </h5>
                    </div>
                    <DataGrid
                        dataSource={this.state.pastedGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnResizingMode={"widget"}
                        // columnMinWidth={100}
                        noDataText={"No data found for the filters applied"}
                        onCellClick={this.onCellClick}
                        ref={(ref) => { this.dataGridRef = ref; }}>
                        <Paging defaultPageSize={20} enabled={false} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} visible={false} />
                        <Editing mode="cell" allowUpdating={false} startEditAction="click" allowDeleting={false} />
                        {/* <SearchPanel visible={true} placeholder={"Search"} /> */}
                        {/* <FilterPanel visible={false} /> */}
                        {/* <Sorting mode="single"/> */}
                         {/* <FilterRow visible={true} applyFilter="auto" />  */}
                        {false && <Column dataField="uuid" caption="UUID" />}
                        <Column dataField="name" caption="NAME" />
                        <Column dataField="email" caption="EMAIL" />
                        <Column dataField="providerId" caption="PROVIDER ID" />
                        <Column dataField="provider" caption="PROVIDER" />
                        <Column dataField="userRole" caption={isInviteFlow ? "USER ROLE" : "CONTACT ROLE"} />
                        <Column dataField="itemFound" caption="CONTACT FOUND?"
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.itemFound) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.itemFound)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.itemFound) }}
                        />
                        {isInviteFlow ? <Column dataField="isUserRecordOk" caption="USER RECORD OK?"
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.isUserRecordOk) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.isUserRecordOk)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.isUserRecordOk) }}
                        />:null}
                        {isInviteFlow ? <Column dataField="isUserRoleOk" caption="USER ROLE OK?"
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.isUserRoleOk) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.isUserRoleOk)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.isUserRoleOk) }}
                        />:null}
                        
                        {isContactFlow ? <Column dataField="isContactRecordOk" caption="CONTACT RECORD OK?"
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.isContactRecordOk) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.isContactRecordOk)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.isContactRecordOk) }}
                        />:null}

                        <Column dataField="itemInvited" caption={isInviteFlow ? "CONTACT INVITED?" : 'CONTACT CREATED'}
                            cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.itemInvited) }}
                            calculateDisplayValue={(rowData: any) => {
                                return this.calculateBooleanContent(rowData?.itemInvited)
                            }}
                            calculateCellValue={(cellInfo: any) => { return this.calculateBooleanContent(cellInfo?.itemInvited) }}
                        />
                        <Column dataField="errorMessage" caption="WARNINGS / ERRORS" 
                        cellComponent={(cellInfo: any) => { return this.displayErrorMessage(cellInfo) }}
                         />
                    </DataGrid>
                    <div className="col-12 col-lg-2 mb-3 mr-auto" >
                        {isInviteFlow ? <button
                            className="btn btn-primary btn--large mt-3"
                            type="button"
                            disabled={!this.state.showFindMatches}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.onClickFindMatches();
                            }}
                        >
                            FIND MATCHES
                        </button> :
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="button"
                                disabled={!this.state.showFindMatches}
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    this.onClickCheckForDuplicates();
                                }}
                            >
                                CHECK FOR DUPLICATES
                            </button>}
                    </div>
                </>
                {this.renderMatchingGrid()}
                <div className="row mt-3" hidden={!this.state.showSendInvites}>
                    {this.isInviteFlow() && <><div className="col-12 col-lg-2 mb-3" >
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="button"
                            disabled={this.isSendInvitesDisabled()}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.onClickSendInvites();
                            }}
                        >
                            SEND INVITES
                        </button>
                    </div>
                        <div className="col-12 col-lg-2 mb-3 ">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="button"
                                disabled={this.isMissingUsersDisabled()}
                                onClick={(e: React.MouseEvent) => {
                                    e.preventDefault();
                                    this.onClickCreateUpdateMissingUsers();
                                }}
                            >
                                CREATE OR UPDATE MISSING USERS
                            </button>
                        </div>
                    </>}
                    {this.isCreateFlow() && <>
                        <div className="col-12 col-lg-2 mb-3" >
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="button"
                            disabled={this.isCreateUserDisabled()}
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault();
                                this.onClickCreateBulkContacts();
                            }}
                        >
                            CREATE CONTACTS
                        </button>
                    </div>
                    </>}
                </div>

            </>
        );
    }
}

export default BulkInvitesContainer;
